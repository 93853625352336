<template>
  <b-card
    no-body
  >
    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">
          <b-form-group
            class="mr-1 mb-md-0"
          >
            <b-button
              v-b-modal.modal-1
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="gradient-primary"
              block
              @click="addData"
            >
              เพิ่มบัญชีถอน KBANK
            </b-button>
          </b-form-group>
          <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative items-center"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="TrashIcon"
              size="16"
              class="mx-1"
              @click="deleteData(data.item.id)"
            />
            <b-tooltip
              title="Preview Invoice"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>

      <b-modal
        ref="my-modal-kbank"
        title="กรุณากรอกข้อมูล"
        size="sm"
        ok-title="ตกลง"
        cancel-title="ยกเลิก"
        no-close-on-backdrop
        @ok="SubmitGenKbank()"
      >
        <b-form-group>
          <label for="dividend">ชื่อบัญชี <span class="text-danger">* ชื่อ - นามสกุลเจ้าของบัญชี </span></label>
          <b-input-group>
            <b-form-input
              v-model="StoreForm.acc_name_kbank"
              type="text"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="เลขบัญชี (ไม่ต้องมี - )"
          label-for="acc_kbank"
        >
          <b-form-input
            id="acc_kbank"
            v-model="StoreForm.acc_kbank"
            placeholder="เลขบัญชีถอนกสิกร"
          />
        </b-form-group>

        <b-form-group
          label="PIN (รหัส App K Plus)"
          label-for="pin_kbank"
        >
          <b-form-input
            id="pin_kbank"
            v-model="StoreForm.pin_kbank"
            placeholder="รหัส App K Plus"
            type="Number"
            maxlength="6"
          />
        </b-form-group>

        <b-form-group
          label="ข้อมูลที่ Gen ผ่าน App"
          label-for="gen_kbank"
        >
          <b-form-textarea
            id="gen_kbank"
            v-model="StoreForm.gen_kbank"
            placeholder="ข้อมูลที่ Gen ผ่าน App"
            type="text"
          />
        </b-form-group>
      </b-modal>
    </b-overlay>
  </b-card>

</template>

<script>
// import vSelect from 'vue-select'
// import moment from 'moment-timezone'
import {
  BModal, BCard, BTable,
  BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput,
  BInputGroupAppend, BButton, BCardBody, VBToggle,
  BOverlay, BIconController, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // vSelect,

    BModal,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    BFormTextarea,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      depositdata: [],
      bankData: '',
      bankname: '',
      bankcode: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'accno', label: 'เลขบัญชี' },
        { key: 'accname', label: 'ชื่อ-สกุล' },
        { key: 'phone', label: 'เบอร์มือถือ' },
        // { key: 'phone', label: 'เบอร์มือถือ' },
        { key: 'actions', label: 'Action' },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      StoreForm: {
        acc_name_kbank: null,
        acc_kbank: null,
        pin_kbank: null,
        gen_kbank: null,
      },
      step: 1,
      show: false,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http
        .get('/setting/withdrawscb/index')
        .then(response => {
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    addData() {
      this.$refs['my-modal-kbank'].show()
    },
    SubmitGenKbank() {
      this.show = true
      const obj = {
        StoreForm: this.StoreForm,
      }
      this.$http
        // eslint-disable-next-line no-undef, block-scoped-var
        .post('/kbankSetting/withdraw', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
          this.Success('เพิ่มข้อมูลบัญชีสำเร็จ')
          this.StoreForm = {
            acc_name_kbank: null,
            acc_kbank: null,
            pin_kbank: null,
            gen_kbank: null,
          }
          this.show = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.SwalError('เพิ่มข้อมูลบัญชีไม่สำเร็จ')
          this.show = false
        })
    },
    deleteData(id) {
      this.$swal({
        title: 'ลบบัญชี?',
        text: 'กรุณายืนยันการลบบัญชี',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          const params = {
            id,
          }
          this.$http
            .get('/setting/withdrawscb/delete', { params })
            .then(response => {
              this.getData()
              if (response.data) {
                this.$swal({
                  icon: 'success',
                  title: '<h3 style="color: #141414">ลบข้อมูลสำเร็จ</h3>',
                  text: '',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => {
              console.log(error)
              this.$swal('เกิดข้อผิดพลาด', error.response.data.message, 'error')
            })
        } else {
          this.$swal('ยกเลิก', '', 'error')
        }
      })
    },
    editData(data) {
      this.bankData = data
      this.bankname = data.name
      this.bankcode = data.bankcode
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
